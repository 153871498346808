import {CanActivateChildFn,CanActivateFn,CanMatchFn,GuardResult,Router} from '@angular/router';
import {filter,firstValueFrom,map} from 'rxjs';
import {inject} from '@angular/core';
import {AuthenticationService} from '../authentication/authentication.service';
import {toObservable} from '@angular/core/rxjs-interop';

export const profileGuardActivate:CanActivateFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user)=>user!==undefined),
		map((user)=>!user ? router.parseUrl('/login') : true)
	));
};

export const profileGuardMatch:CanMatchFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user)=>user!==undefined),
		map((user)=>!user ? router.parseUrl('/login') : true)
	));
};

export const profileGuardActivateChild:CanActivateChildFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user)=>user!==undefined),
		map((user)=>!user ? router.parseUrl('/login') : true)
	));
};

